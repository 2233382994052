<template>
  <div :style="dynamicWrapperStyle" class="collapse-transition__wrapper">
    <div
      ref="slotWrapper"
      :data-ref="$options.consts.REF"
      class="collapse-transition__height-observer"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'

const DEFAULT_TRANSITION_DELAY_MS = 500

export default {
  name: 'ACollapseTransition',
  props: {
    delayMs: propValidator(
      [PROP_TYPES.NUMBER],
      false,
      DEFAULT_TRANSITION_DELAY_MS
    )
  },
  consts: {
    REF: 'collapse-transition-height-observer'
  },
  data() {
    return {
      wrapperHeight: null,
      slotHeight: null,
      observerRemover: null
    }
  },
  computed: {
    dynamicWrapperStyle() {
      if (!this.wrapperHeight) {
        return { height: 'auto', transition: `height ${this.delayMs}ms` }
      }

      return {
        height: `${this.wrapperHeight}px`,
        transition: `height ${this.delayMs}ms`
      }
    }
  },
  watch: {
    slotHeight: {
      async handler(newVal) {
        this.wrapperHeight = newVal || null
      }
    }
  },
  methods: {
    getSlotHeight() {
      const slotWrapperEl = this.$refs?.slotWrapper

      if (!slotWrapperEl) return

      /**
       * From the link:
       * This basically means the event of resize happens before the paint and
       * if you do a resize to something that causes another event (before the
       * paint) you could cause an infinite loop.
       * @link https://stackoverflow.com/questions/76187282/react-resizeobserver-loop-completed-with-undelivered-notifications
       */
      requestAnimationFrame(() => {
        this.slotHeight = slotWrapperEl.clientHeight
      })
    }
  },
  mounted() {
    this.observerRemover = this.$helper.runOnRefResize({
      dataRef: this.$options.consts.REF,
      fn: this.getSlotHeight.bind(this)
    })
  },
  beforeDestroy() {
    if (this.observerRemover) {
      this.observerRemover()
    }
  }
}
</script>

<style scoped lang="scss">
.collapse-transition__wrapper {
  overflow: hidden;
}
</style>
